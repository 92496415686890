import React from "react";
import { PulseLoader } from "react-spinners";

function Loading() {
  return (
    <div className="center-screen">
      <PulseLoader color="white" size={50} />
    </div>
  );
}
export default Loading;
