import React, { useState } from "react";
import moment from "moment";
import _get from "lodash/get";
import _trim from "lodash/trim";
import { Col, Card, CardBody } from "reactstrap";
import { getCalcValue } from "../utils/calculations";
import translateText, { translateTimestamp } from "./translate";

export const getDisplayVal = (data, type, name, config, dateFormat, timeFormat) => {
  let displayVal = _get(data, [name, "label"], "");
  const val = _get(data, [name, "value"], "");
  if (type === "multiselect") {
    const vals = _get(data, [name, "value"], []);
    if (typeof vals !== "object") {
      return `${_get(data, [name, "label"], "")}`
        .split(",")
        .map((m) => translateText(_trim(m), true))
        .join(", ");
    }
    return (_get(data, [name, "value"], []) || []).map((m) => translateText(m.label, true)).join(", ");
  } else if (displayVal && type === "timestamp") {
    return translateTimestamp(moment(displayVal).format(`${dateFormat} ${timeFormat}`), dateFormat || "");
  } else if (displayVal && type === "date") {
    return translateTimestamp(moment(displayVal).format(dateFormat), dateFormat);
  } else if (type === "select") {
    return val.includes(",") ? displayVal : translateText(displayVal, true);
  } else if (type === "readonly" && (config || {}).calculation) {
    const calcValue = getCalcValue((config || {}).calculation, data);
    return `${calcValue.value !== null ? calcValue.value : ""} ${calcValue.unit || ""}`;
  } else if (type === "numeric") {
    return displayVal;
  }
  return translateText(displayVal, true);
};

export default function CardView(props) {
  const {
    size,
    onClick,
    parameters,
    data,
    cols = 1,
    settings,
    dateFormat,
    timeFormat,
    expandable,
    defaultExpanded,
    fullCardHeight,
    id,
    operators,
  } = props;

  const [isExpanded, setIsExpanded] = useState(expandable ? defaultExpanded : false);

  let headerLeft = translateTimestamp(moment(data.created_date).format(`${dateFormat} ${timeFormat}`), dateFormat);
  let headerRightLabel = "author";
  let headerRightValue = data.created_by;
  const hiddenParams = [];
  if ((settings || {}).headerLeft) {
    const [headerLeftParam] = parameters.filter((f) => f.name === settings.headerLeft);
    if (headerLeftParam) {
      headerLeft = getDisplayVal(data, headerLeftParam.type, headerLeftParam.name, headerLeftParam.config, dateFormat, timeFormat) || "N/A";
      if ((settings || {}).headerLeftHidden) {
        hiddenParams.push(headerLeftParam.name);
      }
    }
  }

  if ((settings || {}).headerRight) {
    const [headerRightParam] = parameters.filter((f) => f.name === settings.headerRight);
    if (headerRightParam) {
      headerRightValue = getDisplayVal(data, headerRightParam.type, headerRightParam.name, headerRightParam.config, dateFormat, timeFormat) || "N/A";
      headerRightLabel = settings.headerRight;
    }
  } else {
    const [findUser] = (operators || []).filter((f) => f.username === headerRightValue);
    if (findUser) headerRightValue = findUser.name;
  }

  const parsedData = parameters.map((e, i) => {
    const style = { ...styles.infoRow };
    const { name, type, config } = e;
    const unit = _get(data, [name, "unit"], "");
    const displayVal = getDisplayVal(data, type, name, config, dateFormat, timeFormat);
    if (cols === 2) style.width = "50%";
    let show = e.is_hidden ? false : true;
    if (e.parent_parameter_id && e.parent_value) {
      const [parentParam] = parameters.filter((f) => f.parameter_id === e.parent_parameter_id);
      if (parentParam) {
        const parentVal = `${_get(data, [parentParam.name, "value"], "")}`;
        if (!(e.parent_value || "").includes(parentVal)) {
          show = false;
        }
      }
    }
    if (hiddenParams.includes(name)) {
      show = false;
    }
    if (show) {
      return (
        <div key={i} style={style}>
          <span style={styles.infoRowLabel}>{translateText(name)}:</span>
          <span style={styles.infoRowValue}>
            {displayVal}
            {unit && ` ${unit}`}
          </span>
        </div>
      );
    }
    return null;
  });

  const contentStyle = { ...styles.cardContent };
  if (expandable && !isExpanded) {
    contentStyle.maxHeight = 85;
    contentStyle.overflow = "hidden";
  }

  return (
    <Col md={size}>
      <Card id={id} onClick={onClick}>
        <CardBody>
          <div id={`${id}_expand_collapse`} style={styles.cardHeader} onClick={onClickHeader}>
            <div style={{ fontSize: 20 }}>
              {expandable && <div style={styles.plusMinusIcon}>{isExpanded ? "-" : "+"}</div>}
              {headerLeft}
            </div>
            <div style={styles.cardHeaderRight}>
              <div style={styles.cardTitleRightBlock}>
                <div style={styles.cardTitleRightLabel}>{translateText(headerRightLabel)}</div>
                <div>{headerRightValue}</div>
              </div>
              {/* <div style={{ marginLeft: 25 }}>
                <FaPlus style={{ color: "var(--primary)" }} />
              </div> */}
            </div>
          </div>
          <div style={contentStyle} className={`card-content ${fullCardHeight ? "full-card-height" : ""}`}>
            {parsedData}
          </div>
        </CardBody>
      </Card>
    </Col>
  );

  function onClickHeader(e) {
    if (!expandable) return;
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  }
}

const styles = {
  table: {
    overflow: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  cardHeader: {
    height: 63,
    paddingLeft: 20,
    paddingRight: 20,
    background: "#292931",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderRight: {
    display: "flex",
    alignItems: "center",
  },
  cardContent: {
    padding: 20,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
    transition: ".2s",
  },
  cardTitleRightBlock: {
    color: "var(--primary)",
    textAlign: "right",
    fontSize: 20,
    lineHeight: 1,
  },
  cardTitleRightLabel: {
    color: "white",
    fontSize: 14,
    marginBottom: 3,
  },
  infoRow: {
    width: "100%",
    marginBottom: 16,
  },
  infoRowLabel: {
    color: "white",
    letterSpacing: 0,
    fontSize: 20,
  },
  infoRowValue: {
    color: "var(--primary)",
    fontWeight: 600,
    letterSpacing: 0,
    marginLeft: 8,
  },
  plusMinusIcon: {
    minWidth: 25,
    display: "inline-block",
  },
};
