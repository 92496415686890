import React from "react";
import Router from "./router";
import { Provider } from "react-redux";
import store from "./redux/store";
import ReduxToastr from "react-redux-toastr";
import { Translate } from "./components/translate";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallbackPage } from "./components/error";

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
      <Provider store={store}>
        <Translate />
        <Router />
        <ReduxToastr timeOut={3000} preventDuplicates position="top-left" closeOnToastrClick transitionIn="fadeIn" transitionOut="fadeOut" />
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
