import React from "react";
import { translateText } from "../components";
import { FaSyncAlt } from "react-icons/fa";
import moment from "moment";
import StackTrace from "stacktrace-js";
import { postDataAPI } from "../api";

window.onerror = function (msg, file, line, col, error) {
  if (process.env.REACT_APP_UI_ENV === "bedside") {
    StackTrace.fromError(error).then((err) => {
      const errObj = {
        url: window.location.href,
        date: moment().toISOString(),
        msg,
        err,
        localStorage: { ...localStorage },
      };
      postDataAPI("/system/log-client-error", errObj);
    });
  }
};

export const ErrorFallbackPage = function () {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 144px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <p style={{ marginBottom: 20, color: "white" }}>{translateText("an_error_has_occured_click_the_button_below_to_reload_the_app")}</p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div id="error_reload" style={{ cursor: "pointer" }} className="btn-primary" onClick={() => (document.location.href = "/")}>
            <FaSyncAlt style={{ marginTop: "-4px" }} /> {translateText("reload")}
          </div>
        </div>
      </div>
    </div>
  );
};
