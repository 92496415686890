import React from "react";
import { Link } from "react-router-dom";
import { translateText } from "../components";
import docboxLogo from "../img/docbox-logo.png";

function PageHeader(props) {
  const { breadcrumbs = [], contentRight } = props;
  document.title = `Docbox / ${breadcrumbs.map((m) => translateText(m.title)).join(" / ")}`;

  const renderBreadcrumbs = () => {
    if (breadcrumbs.length === 1) {
      return translateText(breadcrumbs[0].title);
    }
    const renderArr = [];
    breadcrumbs.forEach((e, i) => {
      let title = "";
      if (i === breadcrumbs.length - 1) {
        title = (
          <span key={i} className="txt">
            {translateText(e.title)}
          </span>
        );
      } else {
        title = (
          <span key={i} className="txt">
            {e.to ? (
              <Link className="grey-crumbs" to={e.to}>
                {translateText(e.title)}
              </Link>
            ) : (
              <span className="grey-crumbs">{translateText(e.title)}</span>
            )}
            <span className="mr-2 ml-2">/</span>
          </span>
        );
      }
      renderArr.push(title);
    });
    return renderArr;
  };

  return (
    <div className="title-area" style={styles.pageHeader}>
      <h1 style={styles.text}>
        <img src={docboxLogo} width="50" className="mr-3 mb-2" />
        {renderBreadcrumbs()}
      </h1>
      {contentRight}
    </div>
  );
}
export default PageHeader;

const styles = {
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    height: 96,
    minHeight: 96,
    maxHeight: 96,
    paddingTop: 6,
  },
  text: {
    width: "inherit",
    marginBottom: 0,
  },
};
