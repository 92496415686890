import React from "react";
import { Button } from "reactstrap";
import translateText from "./translate";

export default function SideNav(props) {
  const { list = [], onClick, active, id } = props;

  return (
    <div style={styles.sideNav}>
      {list.map((e, i) => {
        if (e) {
          const isActive = active === i;
          const style = { ...styles.button };
          if (isActive) style.backgroundColor = "rgba(75, 187, 231, 0.3)";
          return (
            <Button id={`${id}_${e.value}`} key={i} onClick={() => onClick(i)} block style={style}>
              {translateText(e.label)} <span className="float-right mr-1">{e.contentRight}</span>
            </Button>
          );
        }
      })}
    </div>
  );
}

const styles = {
  sideNav: {
    minWidth: 250,
    width: 250,
    marginRight: 20,
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  button: {
    backgroundColor: "var(--grey)",
    minHeight: 53,
    fontWeight: 500,
    fontSize: 20,
    paddingTop: 15,
    textAlign: "left",
    lineHeight: 1,
  },
};
