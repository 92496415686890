import React from "react";
import { Table } from "reactstrap";
import translateText from "./translate";

function CardTable({ columns, data, show_list_header, alignment = "left" }) {
  return (
    <Table striped>
      <thead className={!show_list_header ? "display-none" : ""}>
        <tr>
          {columns.map((col) => (
            <th key={col.accessor}>
              <span>{col.header}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data || []).map((row, i) => (
          <tr key={i}>
            {columns.map((col) => {
              return (
                <td key={col.accessor} className={`text-${alignment} pl-4 nowrap`}>
                  {col.type === "select" ? translateText(row[col.accessor].label, true) : row[col.accessor].label}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
export default CardTable;
