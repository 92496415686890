import { createStore, combineReducers } from "redux";
import app from "./reducer";
import { reducer as toastrReducer } from "react-redux-toastr";

const reducers = {
  app,
  toastr: toastrReducer,
};

export default createStore(combineReducers(reducers));
