import moment from "moment";
import lo from "lodash";

export const FUNCTIONS = {
  multiply: (param1, param2) => {
    return { value: lo.multiply(param1, param2) };
  },
  dateDiff: (param1, param2, unit) => {
    let date1;
    if (param1 === "now") {
      date1 = moment();
    } else {
      date1 = moment(param1);
    }
    let date2;
    if (param2 === "now") {
      date2 = moment();
    } else {
      date2 = moment(param2);
    }
    if (!unit) {
      let calcUnit = "years";
      let value = date1.diff(date2, calcUnit);
      if (value === 0) {
        calcUnit = "months";
        value = date1.diff(date2, calcUnit);
        if (value === 0) {
          calcUnit = "weeks";
          value = date1.diff(date2, calcUnit);
          if (value === 0) {
            calcUnit = "days";
            value = date1.diff(date2, calcUnit);
            if (value === 0) {
              calcUnit = "hours";
              value = date1.diff(date2, calcUnit);
              if (value === 0) {
                calcUnit = "minutes";
                value = date1.diff(date2, calcUnit);
              }
            }
          }
        }
      }
      return {
        value,
        unit: calcUnit,
      };
    }
    return {
      value: date1.diff(date2, unit),
      unit,
    };
  },
};

function parseParameters(name, data) {
  let returnData = data[name];
  if (name === "date_of_birth" && lo.isObject(data[name])) {
    const dobYear = `${data[name].century}${lo.padStart(data[name].year, 2, 0)}`;
    returnData = moment(`${data[name].month}/${data[name].day}/${dobYear}`, "M-D-YYYY").format("YYYY-MM-DD");
  } else if (lo.get(data, [name, "value"], "")) {
    returnData = data[name].value;
  } else if (lo.get(data, name, "")) {
    returnData = data[name];
  }
  if (!returnData) {
    for (const k in data) {
      if (k.includes(`${name}_`)) {
        returnData = data[k].value;
        break;
      }
    }
  }
  return returnData;
}

export const getCalcValue = ({ function_name, parameters }, data) => {
  try {
    const params = [];
    for (const p in parameters) {
      const e = parameters[p];
      if (lo.isObject(e)) {
        params.push(FUNCTIONS[e.function_name](...e.parameters));
      } else if (e[0] === "$") {
        const paramVar = e.substring(1);
        const paramVal = parseParameters(paramVar, data);
        if (!paramVal) return { value: null, unit: null };
        params.push(paramVal);
      } else {
        params.push(e);
      }
    }
    return FUNCTIONS[function_name](...params);
  } catch (e) {
    console.log(e);
    return {
      value: null,
      unit: null,
    };
  }
};
