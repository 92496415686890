import React from "react";
import { Button } from "reactstrap";
import { translateText } from "../components";

export default function TopNavigator(props) {
  const { list = [], onClick, active, id } = props;

  return (
    <div className="top-navigator" style={styles.wrapper}>
      {list.map((e, i) => {
        const style = { ...styles.button };
        if (i === 0) style.marginLeft = 0;
        if (i === active) style.backgroundColor = "var(--secondary)";
        return (
          <Button id={`${id}_${e.name}`} key={i} onClick={() => i !== active && onClick(i)} block style={style}>
            {translateText(e.name)}
          </Button>
        );
      })}
    </div>
  );
}

const styles = {
  wrapper: {
    overflowX: "auto",
    display: "flex",
    height: 70,
    marginBottom: 10,
  },
  button: {
    margin: 0,
    marginLeft: 10,
    whiteSpace: "nowrap",
    display: "inline",
    width: "auto",
    flexShrink: 0,
    backgroundColor: "var(--grey)",
    minWidth: 200,
    transition: "none",
  },
};
