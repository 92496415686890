import _toLower from "lodash/toLower";
import _startCase from "lodash/startCase";
import { useSelector } from "react-redux";

const SHORT_MONTH = ["Jan", ",Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const LONG_MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function translateText(t, keepOrig) {
  return Translate.translateText(t, keepOrig);
}

export function translateTimestamp(t, dateFormat) {
  return Translate.translateTimestamp(t, dateFormat);
}

export function Translate(props) {
  const { text, keepOrig } = props;
  const translations = useSelector((state) => {
    return state.app.system.translations || {};
  });

  Translate.translateText = function (t, keepOrig) {
    if (!t) return "";
    if (translations[t]) return translations[t];
    let key = _toLower(t).replaceAll(/\W+/g, "_");
    if (key.charAt(key.length - 1) === "_") {
      key = key.slice(0, -1);
    }
    if (!translations[key]) return keepOrig ? t : _startCase(t);
    return translations[key];
  };

  Translate.translateTimestamp = function (value, dateFormat) {
    let translated = value;
    if (dateFormat.includes("MMMM")) {
      LONG_MONTH.forEach((e) => {
        if (translated.includes(e)) {
          translated = translated.replace(e, translations[_toLower(e)]);
        }
      });
    } else if (dateFormat.includes("MMM")) {
      SHORT_MONTH.forEach((e) => {
        if (translated.includes(e)) {
          translated = translated.replace(e, translations[_toLower(e)]);
        }
      });
    }
    return translated;
  };

  return Translate.translateText(text, keepOrig);
}
