import React, { useState } from "react";
import { Table } from "reactstrap";
import FormField from "./formField";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import _set from "lodash/set";
import { FaSortAlphaDownAlt, FaSortAlphaUp, FaTrashAlt, FaCircle } from "react-icons/fa";
import translateText from "./translate";
import ConfirmationModal from "./confirmationModal";

function InputTable(props) {
  const {
    columns,
    data = [],
    setData,
    handleDelete,
    defaultReadOnly = false,
    onClickEffect,
    showDelete = true,
    stickyFirstColumn = false,
    disableEdit,
    showPencil,
    useTouchInputNav = true,
    groupColor,
    expandable = false,
    defaultExpanded,
    striped = false,
    hideUnits,
    id,
  } = props;

  const [deleteIdx, setDeleteIdx] = useState(null);
  const [isExpanded, setIsExpanded] = useState(expandable ? defaultExpanded : null);

  const deleteRow = function () {
    if (handleDelete) {
      handleDelete(deleteIdx);
    } else {
      const update = _cloneDeep(data);
      update.splice(deleteIdx, 1);
      setData(update);
    }
    setDeleteIdx(null);
  };

  const groupings = {};
  let showGroupings = false;
  for (const col of columns) {
    const group = col.groupBy || "none";
    if (group !== "none") showGroupings = true;
    if (group in groupings) groupings[group]++;
    else groupings[group] = 1;
  }

  let className = "input-table";
  if (stickyFirstColumn) className += " sticky-first-col";
  if (striped) className += " striped";

  function renderExpandCollapse() {
    if (isExpanded) return "-";
    else return "+";
  }

  return (
    <>
      {deleteIdx !== null && (
        <ConfirmationModal
          id={`${id}_delete_confirm_modal`}
          isOpen={deleteIdx !== null ? true : false}
          close={() => setDeleteIdx(null)}
          handleConfirm={deleteRow}
          body={translateText("are_you_sure_you_want_to_delete")}
          title={translateText("delete_confirmation")}
          submitText={translateText("delete")}
          size="lg"
        />
      )}
      <Table className={className}>
        <thead>
          {showGroupings && (
            <tr>
              {Object.keys(groupings).map((groupName) => {
                const colSpan = groupings[groupName];
                if (groupName === "none") return <th key={groupName} colSpan={colSpan} style={{ paddingBottom: 0 }} />;
                else
                  return (
                    <th
                      id={`${id}_expand_collapse`}
                      key={groupName}
                      colSpan={colSpan}
                      style={{ paddingBottom: 0 }}
                      onClick={() => expandable && setIsExpanded(!isExpanded)}
                    >
                      <div style={{ ...styles.group, backgroundColor: groupColor, color: "white" }}>
                        <span style={styles.groupName}>
                          {expandable && renderExpandCollapse()} {translateText(groupName)}
                        </span>
                      </div>
                    </th>
                  );
              })}
              {showDelete && <th style={{ width: 50 }} />}
            </tr>
          )}
          {((expandable && isExpanded) || !expandable) && (
            <tr>
              {columns.map((col, i) => {
                const { header, onSort, width, sort, accessor, name } = col;
                const divStyle = {
                  fontSize: 18,
                  height: "100%",
                };
                const thStyle = {};
                if (i === 0 && stickyFirstColumn) thStyle.zIndex = 0;
                if (showGroupings) thStyle.top = "60px";
                if (width) {
                  thStyle.maxWidth = width;
                  thStyle.minWidth = width;
                  thStyle.width = width;
                }
                let sortIcon;
                const colName = name ? name : accessor;
                if (onSort && ((sort.field || "").includes(colName) || (sort.accessor || "").includes(colName))) {
                  sortIcon = <FaSortAlphaUp style={{ marginBottom: 5, marginRight: 5 }} />;
                  if (sort.isDesc) sortIcon = <FaSortAlphaDownAlt style={{ marginBottom: 5, marginRight: 5 }} />;
                }
                return (
                  <th id={`${id}_${colName}_sort`} key={i} style={thStyle} onClick={() => onSort && onSort()}>
                    <div style={divStyle}>
                      {sortIcon}
                      {header}
                    </div>
                  </th>
                );
              })}
              {showDelete && <th style={{ width: 50 }} />}
            </tr>
          )}
        </thead>
        {((expandable && isExpanded) || !expandable) && (
          <tbody>
            {(data || []).map((row, i) => {
              if (row.__action !== "delete") {
                return (
                  <tr key={i}>
                    {columns.map((col, j) => {
                      const { type, list, units, format, accessor, name } = col;
                      const rowData = _get(row, accessor);
                      const cell = format ? format(rowData) : rowData;
                      let readOnly = defaultReadOnly;
                      if (cell) readOnly = cell.readOnly;
                      if (disableEdit) readOnly = true;
                      return (
                        <td key={j}>
                          {(rowData || {}).has_edit && (
                            <FaCircle size={8} style={{ color: "var(--primary)", position: "absolute", marginTop: 6, marginLeft: 6 }} />
                          )}
                          <FormField
                            name={name ? name : accessor}
                            type={type}
                            value={cell}
                            list={list}
                            onChange={(res) => {
                              const update = _cloneDeep(data);
                              const oldData = _get(update[i], accessor);
                              _set(update[i], accessor, { ..._get(update[i], accessor), ...res });
                              if (!_isEqual((oldData || {}).value, (res || {}).value) || !_isEqual((oldData || {}).unit, (res || {}).unit)) {
                                _set(update[i], `${accessor}.updated`, true);
                              }
                              setData(update);
                            }}
                            table={true}
                            showPencil={showPencil}
                            readOnly={readOnly || type === "readonly"}
                            units={units}
                            onClickEffect={onClickEffect}
                            config={col.config}
                            uniqueId={`${i}_${name ? name : accessor}`}
                            useTouchInputNav={useTouchInputNav}
                            hideUnits={hideUnits}
                            data={row}
                          />
                        </td>
                      );
                    })}
                    {showDelete && (
                      <td style={{ verticalAlign: "middle" }}>
                        <FaTrashAlt id={`${id}_${i}_delete`} className="pen-icon-color ml-2" onClick={() => setDeleteIdx(i)} />
                      </td>
                    )}
                  </tr>
                );
              }
              return "";
            })}
          </tbody>
        )}
      </Table>
    </>
  );
}

export default InputTable;

const styles = {
  group: {
    backgroundColor: "rgba(255, 255, 255, 0.02)",
    border: "2px solid rgba(255, 255, 255, 0.1)",
    height: 50,
    marginBottom: 10,
    fontSize: 20,
    display: "flex",
    position: "relative",
    justifyContent: "left",
  },
  groupName: {
    position: "sticky",
    left: 40,
  },
};
