import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function TouchInputHandler(props) {
  const { offset = 0, spaceAbove = 240, spaceBelow = 0 } = props;

  const element = useSelector((state) => state.app.touchInput.element);
  const top = useSelector((state) => state.app.touchInput.top);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (element) {
      const myScrollTop = scrollRef.current.scrollTop;
      scrollRef.current.scrollTo({
        top: top + myScrollTop - spaceAbove - 60 + offset,
        behavior: "smooth",
      });
    } else
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, [element]);

  let height = `calc(100vh - ${spaceAbove}px - ${spaceBelow}px)`;
  if (props.height) height = props.height;

  const containerStyle = { ...styles.scrollContainer, height };

  return (
    <div ref={scrollRef} style={containerStyle}>
      <div style={{ height }}>{props.children}</div>
      <div style={{ height: 800 }} />
    </div>
  );
}

const styles = {
  scrollContainer: {
    maxHeight: "100%",
    overflow: "hidden",
  },
};
