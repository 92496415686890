import axios from "axios";
import config from "./config";

const axiosInstance = axios.create({
  baseURL: config.API_URL,
});

export const postDataAPI = async (uri, payload) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    if (localStorage.getItem("ip")) config.headers.vpn_ip = localStorage.getItem("ip");
    if (localStorage.getItem("port")) config.headers.vpn_port = localStorage.getItem("port");
    const response = await axiosInstance.post(uri, payload, config);
    if (process.env.REACT_APP_UI_ENV === "cloud" && (response.data || {}).redirectUrl) {
      window.location.href = response.data.redirectUrl;
    }
    return response.data;
  } catch (e) {
    if (e.response && e.response.status === 401 && uri !== "/system/signin") {
      localStorage.clear();
      window.location.href = "/login";
      console.log("user not authenticated, redirect to Signin");
    }
    console.log(e);
    return e.response ? { error: e.response } : { error: e };
  }
};

export const logout = async (user, callback) => {
  postDataAPI("/system/logout-user", user);
  const currentUserId = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  if (username === user.username || currentUserId === `${user.user_id}` || !currentUserId) {
    await localStorage.clear();
  }
  if (callback) callback();
};
