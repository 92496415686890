import React, { useState, useEffect } from "react";
import { cloneDeep, groupBy } from "lodash";
import { Button } from "reactstrap";
import { ModalHeader, openTouchInput } from "./index";
import translateText from "../translate";

// multi select

export default function Multi(props) {
  const { inputName, defaultValue, element, list, config = {}, disableDelete, headerTitle } = props;

  // [{}, {}, ...]
  const [values, setValues] = useState(null);
  const [cursor, setCursor] = useState(-1);

  (list || []).forEach((input) => {
    if (!input.group) input.group = "Other";
  });

  const groups = [];
  (list || []).forEach((e) => {
    if (!groups.includes(e.group)) {
      groups.push(e.group);
    }
  });

  const defaultGroup = groups[0];
  const [group, setGroup] = useState(defaultGroup);

  // initialize values whenever element changes
  useEffect(() => {
    setValues(defaultValue || []);
    const [getDefaultGroup] = (list || []).filter(
      (f) => f.value === ((defaultValue || [])[0] || {}).value || `${f.list_item_id}` === `${((defaultValue || [])[0] || {}).list_item_id}`
    );
    if (getDefaultGroup) {
      setGroup(getDefaultGroup.group);
    } else {
      setGroup(defaultGroup);
    }
  }, [element]);

  let options = cloneDeep(list);

  // filter options by group
  options = (options || []).filter((x) => x.group === group);

  let parent = null;
  if (cursor > -1) {
    options = cloneDeep(list[cursor].children);
    parent = list[cursor];
  }

  // append custom multiselect values to options
  if (values) {
    const vals = (list || []).map((x) => x.value);
    for (const x of values) {
      if (!vals.includes(x.value)) options.push(x);
    }
  }

  return (
    <>
      <ModalHeader
        id="multiselect"
        onDelete={
          disableDelete
            ? null
            : () => {
                props.onChange([]);
                setValues([]);
              }
        }
        title={renderTitle()}
        onBack={cursor > -1 ? () => setCursor(-1) : null}
        uniqueId={props.uniqueId}
        useTouchInputNav={props.useTouchInputNav}
      />
      {renderGroups()}
      <div style={styles.selectionWrapper}>{renderOptions()}</div>
    </>
  );

  function renderTitle() {
    return (
      <>
        {headerTitle || translateText(inputName)}
        {cursor > -1 && <> / {translateText(parent.label)}</>}
      </>
    );
  }

  function renderGroups() {
    if (groups.length < 2) return;
    return (
      <div style={styles.groupWrapper}>
        {groups.map((e, i) => {
          let isSelected = e === group;
          const style = { backgroundColor: "var(--grey)" };
          if (isSelected) style.backgroundColor = "var(--secondary)";

          return (
            <Button
              id={`${inputName}_multiselect_group_${i}`}
              key={i}
              className="mr-2 ml-2 active"
              style={style}
              onMouseDown={(ev) => {
                ev.preventDefault();
                // set the active tab which will filter the list with groups = selected group
                setGroup(e);
                // clear the current values
                props.onChange([]);
                setValues([]);
              }}
            >
              {translateText(e)}
            </Button>
          );
        })}
      </div>
    );
  }

  function renderOptions() {
    if (!options || !options[0]) return <div className="mt-4">{translateText("no_options_available")}</div>;

    // subgroup rendering
    if ((config || {}).has_sub_groups) {
      const subGroups = groupBy(options, (x) => (x.config || {}).sub_group || "");
      // ensure more than one subgroup is present
      if (Object.keys(subGroups).length > 1)
        return Object.keys(subGroups).map((name) => (
          <div key={name} style={styles.subGroup}>
            <div style={styles.subGroupName}>{translateText(name)}</div>
            <div style={styles.subGroupContent}>
              {subGroups[name].map((e, i) => {
                let isSelected = (values || []).map((m) => m.value).includes(e.value);

                const style = { ...styles.subGroupOption };
                if (isSelected) style.backgroundColor = "var(--secondary)";
                if (i === 0) style.marginTop = 0;
                return (
                  <Button
                    id={`${inputName}_multiselect_sub_group_${i}`}
                    key={i}
                    style={style}
                    onMouseDown={(ev) => {
                      ev.preventDefault();
                      onSelect(e);
                    }}
                  >
                    {translateText(e.label, true)}
                  </Button>
                );
              })}
            </div>
          </div>
        ));
    }

    // default rendering
    return options.map((e, i) => {
      let isSelected = (values || []).map((m) => m.value).includes(e.value);
      const style = { ...styles.option };
      if (isSelected) style.backgroundColor = "var(--secondary)";
      return (
        <Button
          id={`${inputName}_multiselect_item_${i}`}
          key={i}
          style={style}
          onMouseDown={(ev) => {
            ev.preventDefault();
            onSelect(e);
          }}
        >
          {translateText(e.label, true)}
        </Button>
      );
    });
  }

  // on toggle button
  function onSelect(e) {
    // free text input if "custom"
    if (e.value === "custom") {
      const list_item_id = e.list_item_id;
      return openTouchInput({
        element,
        inputName: props.inputName,
        useTouchInputNav: true,
        uniqueId: props.uniqueId,
        onChange: (e) => {
          const update = cloneDeep(values);
          update.push({ label: e, value: e, list_item_id, isCustom: true });
          props.onChange(update);
        },
        defaultValue: "",
        type: "text",
        onPressEnter: (e) => {
          const update = cloneDeep(values);
          update.push({ label: e, value: e, list_item_id, isCustom: true });
          return openTouchInput({
            element,
            inputName: props.inputName,
            onChange: props.onChange,
            defaultValue: update,
            type: "multiselect",
            list,
            useTouchInputNav: true,
            uniqueId: props.uniqueId,
          });
        },
        onBack: (e) => {
          const update = cloneDeep(values);
          if (e) update.push({ label: e, value: e, list_item_id, isCustom: true });
          openTouchInput({
            element,
            inputName: props.inputName,
            onChange: props.onChange,
            defaultValue: update,
            type: "multiselect",
            list,
            useTouchInputNav: true,
            uniqueId: props.uniqueId,
          });
        },
      });
    }

    // otherwise toggle value
    const update = cloneDeep(values);
    const idx = update.findIndex((f) => f.value === e.value);
    if (idx === -1) {
      // not present in values
      if ((config || {}).has_sub_groups) {
        // find subgroup name of incoming value
        const sg = (options.find((x) => x.value === e.value).config || {}).sub_group || "";
        // search values for a value w/ incoming subgroup
        const sgidx = update.findIndex((x) => ((x.config || {}).sub_group || "") === sg);
        // untoggle if present
        if (sgidx > -1) update.splice(sgidx, 1);
      }
      update.push(e);
    } else update.splice(idx, 1); // already in values - untoggle
    setValues(update);
    props.onChange(update, e);
  }
}

const styles = {
  selectionWrapper: {
    height: "420px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
    padding: "5px 20px",
  },
  groupWrapper: {
    paddingLeft: 38,
    paddingTop: 30,
    textAlign: "left",
  },
  option: {
    width: "20%",
    backgroundColor: "var(--grey)",
    margin: 8,
  },
  subGroupOption: {
    backgroundColor: "var(--grey)",
    marginTop: 16,
  },
  subGroup: {
    width: "20%",
    margin: 8,
  },
  subGroupName: {
    height: 60,
    paddingBottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "underline",
    textUnderlineOffset: 3,
  },
  subGroupContent: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 284,
    overflowY: "auto",
  },
};
