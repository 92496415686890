import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { PulseLoader } from "react-spinners";

// backgroundColor: [normal, active],
// color: [normal, active],

const schemes = {
  primary: {
    backgroundColor: ["var(--primary)", "var(--secondary)"],
    color: ["black", "white"],
  },
  secondary: {
    backgroundColor: ["var(--secondary)", "var(--grey)"],
    color: ["white", "white"],
  },
};

export default function AppButton(props) {
  const { color = "primary", disabled, className, title, onClick, waiting, block = false, isActive, id } = props;
  if (!Object.keys(schemes).includes(color)) throw new Error(`need scheme for button type "${color}"`);

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const style = { ...styles.button };
  style.backgroundColor = schemes[color].backgroundColor[active ? 1 : 0];
  style.color = schemes[color].color[active ? 1 : 0];

  if (block) {
    style.display = "block";
    style.width = "100%";
  }

  return (
    <Button
      id={id}
      style={{ ...style, ...props.style }}
      color={color}
      onClick={onClick}
      disabled={disabled || waiting}
      className={className}
      onTouchStart={() => {
        if (!disabled || waiting) setActive(true);
      }}
      onTouchEnd={() => setActive(false)}
    >
      <div style={styles.content}>
        {waiting && (
          <div style={styles.loader}>
            <PulseLoader size={10} />
          </div>
        )}
        <div>{title || props.children}</div>
      </div>
    </Button>
  );
}

const styles = {
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    marginRight: 10,
  },
  button: {
    outline: "none",
    boxShadow: "none",
    transition: ".1s",
    border: "none",
  },
};
