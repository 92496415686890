import React, { useRef } from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import _toNumber from "lodash/toNumber";
import _isNumber from "lodash/isNumber";
import translateText, { translateTimestamp } from "./translate";
import { Input, Button } from "reactstrap";
import { FaAngleDown, FaAngleUp, FaPen, FaLaptopMedical } from "react-icons/fa";
import { openTouchInput } from "./touchInput";
import moment from "moment";
import { getCalcValue } from "../utils/calculations";

export const getSubGroupsDisplay = (has_sub_groups, inputValue, list, render_type, useLineBreaks) => {
  const returnData = {
    displayText: "",
    sum: 0,
    hasScore: false,
  };
  if (has_sub_groups) {
    for (const x of inputValue || []) {
      const li = list.find((y) => y.value === x.value) || {};
      if (_isNumber((li.config || {}).score)) {
        returnData.hasScore = true;
        returnData.sum += li.config.score;
      }
    }
    const displayArray = (inputValue || []).map((x) => {
      const li = list.find((y) => y.value === x.value) || {};
      return `${(li.config || {}).sub_group ? `${translateText((li.config || {}).sub_group)}: ` : ""}${
        (li.config || {}).score || translateText(x.label)
      }`;
    });
    returnData.displayText = displayArray.join(", ");
    if (useLineBreaks) returnData.displayText = displayArray.join("\n\n");
    if (render_type === "group_sum") {
      const sums = {};
      for (const x of inputValue || []) {
        const li = list.find((y) => y.value === x.value) || {};
        const group = li.group;
        if (!(group in sums)) sums[group] = 0;
        if (_isNumber((li.config || {}).score)) sums[group] += li.config.score;
        else sums[group] += parseInt(li.label);
      }
      returnData.displayText = Object.keys(sums)
        .map((group) => `${translateText(group)}: ${sums[group]}`)
        .join(", ");
    }
  }
  return returnData;
};

function FormField(props) {
  const inputRef = useRef();
  const {
    name,
    type,
    list,
    table = false,
    value,
    units,
    readOnly,
    inModal,
    onClickEffect,
    isFull = false,
    isFilter = false,
    hideLabel,
    inputWidth = "50%",
    config,
    showPencil,
    useTouchInputNav = true,
    hideUnits,
    data,
    fromDevice,
    listOptionsWidth,
  } = props;

  const inputValue = type === "multiselect" ? (value || {}).value : value;
  const { min, max, has_sub_groups, render_type } = config || {};
  const unit = !hideUnits ? (inputValue || {}).unit || (units || [])[0] || "" : "";

  const systemInfo = useSelector((state) => {
    return state.app.system.system_info;
  });

  function onChange(e, u) {
    if (type === "text" || type === "numeric" || type === "timestamp" || type === "date") {
      e = { label: e, value: e };
      e.unit = u || (units || [])[0] || undefined;
    } else if (type === "multiselect") {
      e = { value: e };
    }
    if (props.onChange) return props.onChange(e);
  }

  let hasUnit = false;
  let displayText = _get(inputValue, "label", "");
  if ((displayText || displayText === 0) && unit) {
    hasUnit = true;
    displayText = `${displayText} ${unit}`;
  }
  if (type === "multiselect") {
    displayText = (inputValue || [])
      .map((m) => (m.isCustom ? (m || {}).label : translateText((m || {}).label, true)))
      .filter((f) => f)
      .join(", ");
  } else if (type === "select") {
    const selInput = inputValue || {};
    displayText = (selInput.value || "").includes(",") ? selInput.label : translateText(selInput.label, true);
    // use config.score instead of label if present
    const li = list.find((x) => x.value === (inputValue || {}).value) || {};
    if (li && li.config && li.config.score) displayText = li.config.score;
  } else if (type === "timestamp") {
    displayText = (inputValue || {}).value
      ? translateTimestamp(moment(inputValue.value).format(`${systemInfo.date_format} ${systemInfo.time_format}`), systemInfo.date_format)
      : "";
  } else if (type === "date") {
    displayText = (inputValue || {}).value ? translateTimestamp(moment(inputValue.value).format(systemInfo.date_format), systemInfo.date_format) : "";
  } else if (type === "readonly" && (config || {}).calculation) {
    hasUnit = true;
    let d = data;
    if (_get(config, "calculation.parameters", []).includes("$date_of_birth")) {
      const patData = useSelector((state) => {
        const [patient] = (state.app.staticData["MySubscriber::MyPatientReader"] || []).filter((f) => f.pat_admit_state && f.pat_admit_state !== 8);
        const patientId = (patient || {}).patient_id;
        return patientId ? patient : {};
      });
      d = { ...patData, ...data };
    }
    const calcValue = getCalcValue(config.calculation, d);
    displayText = `${calcValue.value !== null ? calcValue.value : ""} ${calcValue.unit || ""}`;
  }
  const { hasScore, sum, displayText: subGroupDisplay } = getSubGroupsDisplay(has_sub_groups, inputValue, list, render_type);
  if (has_sub_groups) {
    displayText = subGroupDisplay;
  }
  // if rendering FormField within a table
  if (table) {
    let className = "";
    if (isInteractive()) className += "input-nav-selector";
    if (has_sub_groups && hasScore) className += " d-flex";
    return (
      <div className={className} id={props.uniqueId} onClick={onClick} style={{ height: "100%", width: "100%" }}>
        {has_sub_groups && hasScore && (
          <div className="input-table-cell mr-2" style={{ whiteSpace: "nowrap", width: "30%" }}>
            {translateText("tot")}: {sum}
          </div>
        )}
        <div className="input-table-cell">
          {renderInput()}
          {!readOnly && showPencil && <FaPen size="14" className="pen-icon-color ml-2" />}
        </div>
      </div>
    );
  }

  let width = "50%";
  if (inModal) width = "100%";
  if (isFull) width = "90%";

  let inputContainerWidth = inputWidth;
  if (isFull) inputContainerWidth = "70%";

  let className = "mb-2 d-flex";
  if (isInteractive()) className += " input-nav-selector";

  return (
    <div onClick={onClick} className={className} id={props.uniqueId} style={{ maxWidth: width, minWidth: width }}>
      {!hideLabel && (
        <Button block className="mr-3" style={styles.label}>
          {fromDevice && <FaLaptopMedical className="float-left" />}
          {translateText(name)}
        </Button>
      )}
      <div className="mr-3" style={{ display: "flex", width: inputContainerWidth, minWidth: inputContainerWidth }}>
        {renderInput()}
      </div>
    </div>
  );

  function isInteractive() {
    if (readOnly) return false;
    return true;
  }

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (isInteractive()) return onClickInput();
  }

  function renderCaret() {
    if (inputValue) {
      const numVal = _toNumber(inputValue.value);
      if (_isNumber(min) && numVal < min) {
        return (
          <span>
            <FaAngleDown size="22" style={styles.caret} />
          </span>
        );
      } else if (_isNumber(max) && numVal > max) {
        return (
          <span>
            <FaAngleUp size="22" style={styles.caret} />
          </span>
        );
      }
    }
    return "";
  }

  function renderInput() {
    if (readOnly || (type !== "numeric" && type !== "text")) {
      let className = "txt-container";
      if (readOnly) className += " read-only";
      return (
        <div ref={inputRef} className={className} tabIndex="1">
          {!table && has_sub_groups && hasScore && (
            <div style={{ width: "20%", marginRight: "10px", whiteSpace: "nowrap" }}>
              {translateText("tot")}: {sum}
            </div>
          )}
          <span style={{ whiteSpace: hasUnit && "nowrap" }}>{displayText}</span>
          {type === "numeric" && table && renderCaret()}
        </div>
      );
    } else if (type === "numeric" || type === "text") {
      return (
        <div style={styles.inputWrapper}>
          <Input spellCheck={false} innerRef={inputRef} type="text" value={displayText} onChange={(e) => onChange(e.target.value)} />
        </div>
      );
    }
  }

  function onClickInput(e) {
    if (readOnly) return;
    if (onClickEffect) {
      if (type === "multiselect") {
        onClickEffect(value);
      } else {
        onClickEffect(inputValue);
      }
    }

    openTouchInput({
      element: inputRef.current ? inputRef.current : e.target,
      inputName: name,
      onChange,
      defaultValue: inputValue,
      type,
      list,
      event: e,
      isFilter,
      units,
      unit,
      config,
      uniqueId: props.uniqueId,
      useTouchInputNav,
      listOptionsWidth,
    });
  }
}

export default FormField;

const styles = {
  caret: {
    color: "var(--primary)",
    marginBottom: 5,
    marginRight: -20,
  },
  label: {
    width: "inherit",
    flexGrow: 1,
    minHeight: "var(--formfield-height)",
  },
  inputWrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  unit: {
    fontSize: "18px",
    backgroundColor: "#ffffffbb",
    marginLeft: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 2,
    borderRadius: ".25em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
};
