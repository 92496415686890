const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost";
const API_URI = process.env.REACT_APP_API_URI || "/api";
const BASE_PORT = process.env.REACT_APP_BASE_PORT;
const API_URL = `${BASE_URL}${BASE_PORT ? `:${BASE_PORT}` : ""}${API_URI}/`;
export default {
  BASE_URL,
  BASE_PORT,
  API_URL,
  PROXY_SOCKET: process.env.REACT_APP_PROXY_SOCKET,
  PROXY_SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
};

export const PATIENT_BARCODE_METRIC_ID = 2394;
export const DEVICE_BARCODE_METRIC_ID = 1;
export const OPERATOR_BARCODE_METRIC_ID = 2503;
