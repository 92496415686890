export const setReduxState = (state) => {
  return {
    type: "SET_REDUX_STATE",
    payload: state,
  };
};

export const updateStaticData = (state) => {
  return {
    type: "UPDATE_STATIC_DATA",
    payload: state,
  };
};

export const updateAlarmData = (state) => {
  return {
    type: "UPDATE_ALARM_DATA",
    payload: state,
  };
};

export const updateFlowsheetState = (state) => {
  return {
    type: "UPDATE_FLOWSHEET_STATE",
    payload: state,
  };
};

export const updateTrendsState = (state) => {
  return {
    type: "UPDATE_TRENDS_STATE",
    payload: state,
  };
};

export const setTaskState = (task_id, state) => {
  return {
    type: "SET_TASK_STATE",
    task_id,
    payload: state,
  };
};

export const delTaskState = (task_id) => {
  if (!task_id) {
    return {
      type: "DEL_TASK_STATE_ALL",
      task_id,
    };
  }
  return {
    type: "DEL_TASK_STATE_ID",
    task_id,
  };
};

export const closeTouchInput = () => {
  return {
    type: "CLOSE_TOUCH_INPUT",
  };
};

export const updateTestsAndLabsState = (state) => {
  return {
    type: "UPDATE_TESTS_AND_LABS_STATE",
    payload: state,
  };
};

export const updatePersistentAppsState = (state) => {
  return {
    type: "UPDATE_PERSISTENT_APPS_STATE",
    payload: state,
  };
};

export const updatePatientSummaryState = (state) => {
  return {
    type: "UPDATE_PATIENT_SUMMARY_STATE",
    payload: state,
  };
};
