import React, { useState, useEffect } from "react";
import _round from "lodash/round";
import { ModalHeader } from "./index";
import { White } from "react-dial-knob";

import translateText from "../translate";

function WhiteDial({ settings, value, setValue, id, label }) {
  return (
    <div>
      <White
        diameter={300}
        min={(settings || {}).min || 0}
        max={(settings || {}).max + 1 || 100}
        step={(settings || {}).step || 1}
        value={value}
        onValueChange={(e) => {
          if (e !== (settings || {}).max + 1) {
            setValue(e);
          }
        }}
        theme={{
          activeNotchColor: "var(--primary)",
          activeTextColor: "var(--primary)",
          defaultNotchColor: "#ffffff33",
        }}
        ariaLabelledBy={"dial"}
      >
        <label style={{ marginTop: 20, fontSize: 28 }}>{label}</label>
      </White>
      <div id={id} style={styles.value}>
        {value / settings.multiplier}
      </div>
    </div>
  );
}

export default function Dial(props) {
  const { defaultValue, inputName, config, onChange, element } = props;
  const { minSettings, maxSettings } = config || {};
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    setMinValue(_round((defaultValue || [])[0]));
    if (maxSettings) {
      setMaxValue(_round((defaultValue || [])[1]));
    }
  }, [element]);

  return (
    <div>
      <ModalHeader id="dial" title={`Alarm Setting ${inputName} Limits`} uniqueId={props.uniqueId} useTouchInputNav={props.useTouchInputNav} />
      <div className="mt-4" style={styles.selectionWrapper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {minSettings && (
            <WhiteDial
              settings={minSettings}
              value={minValue}
              setValue={setMinValue}
              id={`${inputName}_custom_input_min`}
              label="Min Setting Value"
            />
          )}
          {minSettings && maxSettings && <div style={{ margin: 100 }} />}
          {maxSettings && (
            <WhiteDial
              settings={maxSettings}
              value={maxValue}
              setValue={setMaxValue}
              id={`${inputName}_custom_input_max`}
              label="Max Setting Value"
            />
          )}
        </div>
        <div style={styles.saveButton} onClick={() => onChange([minValue, maxValue])}>
          {translateText("save")}
        </div>
      </div>
    </div>
  );
}

const styles = {
  saveButton: {
    backgroundColor: "var(--secondary)",
    height: 50,
    padding: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    width: 200,
    borderRadius: 10,
    position: "fixed",
    bottom: 30,
    right: 30,
  },
  selectionWrapper: {
    height: "420px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    overflow: "auto",
  },
  value: {
    padding: "24px 8px 8px 8px",
    fontSize: 65,
    width: 200,
    position: "relative",
    bottom: 230,
    left: 50,
  },
};
